import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BlogSectionWrapper from './blogSection.style';

const BlogSection = ({
  row,
  secTitleWrapper,
  secTitle,
  secDescription,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        BLOG {
          link
          title
          id
          thumbnail_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BlogSectionWrapper id="works">
      <Container>
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Recent works" />
          <Text
            {...secDescription}
            content="Here are some of my recent works. Using technology, I aid with user-focused digital transformation."
          />
        </Box>

        <Box className="row" {...row}>
          {Data.dataJson.BLOG.map((post, index) => (
            <FeatureBlock
              key={`post_key-${index}`}
              id={`post_id-${post.id}`}
              className="blog__post"
              icon={
                <Image
                  fluid={
                    (post.thumbnail_url !== null) | undefined
                      ? post.thumbnail_url.childImageSharp.fluid
                      : {}
                  }
                  alt={`Blog Image ${post.id}`}
                  objectFit="cover"
                  className="blog__image"
                />
              }

            />
          ))}
        </Box>
      </Container>
    </BlogSectionWrapper>
  );
};

// BlogSection style props
BlogSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
};

// BlogSection default style
BlogSection.defaultProps = {
  secTitleWrapper: {
    width: '100%',
    mb: ['50px', '65px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    textAlign: 'center',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    textAlign: 'center',
    mb: '0',
  },
  // Blog post row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-12px',
    mr: '-12px',
  }
};

export default BlogSection;
