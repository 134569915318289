import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Container from 'common/src/components/UI/Container';
import SocialProfile from '../SocialProfile';

import {
  FooterWrapper,
} from './footer.style';
import { heart } from 'react-icons-kit/fa/heart';

const Footer = ({
  row,
  col,
  copyrightStyle,
  flexBox,
  noMargin,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        FOOTER_MENU {
          label
          path
        }
        SOCIAL_PROFILES {
          image {
            publicURL
          }
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box {...row} {...noMargin} {...flexBox}>
          <Box {...col}>
            <Text
              as="span"
              content=" Five Nines Digital."
              {...copyrightStyle}
            />
            <Icon icon={heart} size={14} className="heart_sign" />
          </Box>
          <Box {...col}>
            <SocialProfile
              className="footer_social"
              items={Data.dataJson.SOCIAL_PROFILES}
              iconSize={40}
            />
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  copyrightStyle: PropTypes.object,
  flexBox: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '70px', '80px', '100px', '100px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 3],
    pl: '15px',
    pr: '15px',
    mb: ['40px', '0', '0', '0', '0', '0'],
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  flexBox: {
    flexBox: true,
    justifyContent: 'space-between',
    // flexWrap: 'wrap'
  },
  noMargin: {
    mb: '0',
  },
};

export default Footer;
