import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import SocialProfile from '../SocialProfile';
import BannerWrapper from './banner.style';

const BannerSection = ({
  row,
  contentArea,
  greetingStyle,
  nameStyle,
  aboutStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        SOCIAL_PROFILES {
          image {
            publicURL
          }
          url
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...contentArea}>
            <Heading content="Hi, I’m" {...greetingStyle} />
            <Heading content="Steve Porter" {...nameStyle} />

            <Text
              content="I'm a freelance web developer and technical evangelist devoted to empowering high performing engineering teams to deliver more business value quickly and efficiently.
              "
              {...aboutStyle}
            />
            <Text
              content="I can help you with your digital transformation stratergy, or increase the output of your engineering team.
              "
              {...aboutStyle}
            />
            <SocialProfile items={Data.dataJson.SOCIAL_PROFILES} />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    alignItems: 'center',
  },
  contentArea: {
    width: ['100%', '100%', '50%', '60%'],
    p: ['65px 0 80px 0', '65px 0 80px 0', '80px 0 60px 0', '0'],
    flexBox: true,
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  greetingStyle: {
    as: 'h3',
    color: '#fff',
    fontSize: ['18px', '18px', '18px', '20px', '30px'],
    fontWeight: '500',
    mb: '8px',
  },
  nameStyle: {
    as: 'h2',
    color: '#fff',
    fontSize: ['38px', '38px', '44px', '60px', '80px'],
    fontWeight: '800',
    mb: '12px',
  },
  aboutStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: '50px',
  },
};

export default BannerSection;
